<li class="sidebar-section">
    <a class="nav-item nav-link shortcut-icon" attr.aria-labelledby="sidebarName-{{section.id}}" [title]="('menu.section.icon.' + section.id) | translate" [routerLink]="itemModel.link">
        <i class="fas fa-{{section.icon}} fa-fw"></i>
    </a>
    <div class="sidebar-collapsible">
        <span id="sidebarName-{{section.id}}" class="section-header-text">
          <ng-container
            *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
        </span>
    </div>
</li>
