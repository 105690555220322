<ng-container *ngVar="(bitstreams$ | async) as bitstreams">
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 0" [label]="label | translate">
    <div class="file-section">
      <div *ngFor="let file of bitstreams; let last=last;">
        <ds-file-download-link [bitstream]="file">
          <span>{{file?.name}}</span>
          <span>({{(file?.sizeBytes) | dsFileSize }})</span>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ds-file-download-link>
        <a *ngIf="(isAuthorized$ | async) && isPdf(file?.name)" class="btn btn-primary" [href]="getAnnotatorUrl(file?.id)">{{'item.page.annotate' | translate}}</a>
      </div>
      <ds-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-loading>
      <div *ngIf="!isLastPage" class="mt-1" id="view-more">
        <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]="" (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
      </div>
      <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
        <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]="" (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
      </div>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>
