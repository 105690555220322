<nav [ngClass]="{'open': !(menuCollapsed | async)}"
     [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
     class="navbar navbar-expand-md navbar-light p-0 navbar-container"
     role="navigation" role="navigation" [attr.aria-label]="'nav.main.description' |translate">
  <div class="container h-100">
    <a class="navbar-brand my-2" routerLink="/home">
      <img src="assets/images/qulto-logo.png" alt="logo"/>
    </a>

    <div id="collapsingNav" class="w-100 h-100">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 h-100">
        <ng-container *ngFor="let section of (sections | async)">
          <ng-container
            *ngComponentOutlet="(sectionMap$ | async).get(section.id).component; injector: (sectionMap$ | async).get(section.id).injector;"></ng-container>
        </ng-container>
      </ul>
    </div>
    <ds-search-navbar class="navbar-collapsed"></ds-search-navbar>
    <ds-lang-switch class="navbar-collapsed"></ds-lang-switch>
    <ds-auth-nav-menu class="navbar-collapsed"></ds-auth-nav-menu>
    <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
  </div>
</nav>

