<div class="form-group w-100 pr-2 pl-2">
    <input type="search"
           class="form-control"
           (click)="$event.stopPropagation();"
           placeholder="{{'dso-selector.placeholder' | translate: { type: typesString } }}"
           [formControl]="input" dsAutoFocus (keyup.enter)="selectSingleResult()">
</div>
<div class="dropdown-divider"></div>
<div class="scrollable-menu list-group">
  <div
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="300"
    [infiniteScrollContainer]="'.scrollable-menu'"
    [fromRoot]="true"
    (scrolled)="onScrollDown()">
    <button class="list-group-item list-group-item-action border-0 disabled"
            *ngIf="listEntries.length == 0">
        {{'dso-selector.no-results' | translate: { type: typesString } }}
    </button>
    <button *ngFor="let listEntry of listEntries"
            class="list-group-item list-group-item-action border-0 list-entry"
            [ngClass]="{'bg-primary': listEntry.indexableObject.id === currentDSOId}"
            title="{{ listEntry.indexableObject.name }}"
            dsHoverClass="ds-hover"
            (click)="onSelect.emit(listEntry.indexableObject)" #listEntryElement>
      <ds-listable-object-component-loader [object]="listEntry" [viewMode]="viewMode"
                                           [linkType]=linkTypes.None [context]="getContext(listEntry.indexableObject.id)"></ds-listable-object-component-loader>
    </button>
    <button *ngIf="hasNextPage"
            class="list-group-item list-group-item-action border-0 list-entry">
      <ds-loading [showMessage]="false"></ds-loading>
    </button>
  </div>
</div>
