<div #sdRef="ngbDropdown" ngbDropdown class="w-100">
  <input ngbDropdownToggle class="form-control custom-select"
         [attr.autoComplete]="model.autoComplete"
         [class.is-invalid]="showErrorMessages"
         [id]="id"
         [name]="model.name"
         [readonly]="model.readOnly"
         [type]="model.inputType"
         [value]="(currentValue | async)"
         (blur)="onBlur($event)"
         (click)="$event.stopPropagation(); openDropdown(sdRef);"
         (focus)="onFocus($event)"
         (keypress)="$event.preventDefault()">

  <div ngbDropdownMenu
       class="dropdown-menu scrollable-dropdown-menu w-100"
       aria-haspopup="true"
       aria-expanded="false"
       aria-labelledby="scrollableDropdownMenuButton">
    <div class="scrollable-menu"
         aria-labelledby="scrollableDropdownMenuButton"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()"
         [scrollWindow]="false">

      <button class="dropdown-item disabled" *ngIf="optionsList && optionsList.length == 0">{{'form.no-results' | translate}}</button>
      <button class="dropdown-item collection-item text-truncate" *ngFor="let listEntry of optionsList" (click)="onSelect(listEntry); sdRef.close()" title="{{ listEntry.display }}">
        {{inputFormatter(listEntry)}}
      </button>
      <div class="scrollable-dropdown-loading text-center" *ngIf="loading"><p>{{'form.loading' | translate}}</p></div>
    </div>

  </div>
</div>


